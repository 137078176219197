<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-3M"
            defaultEnd="1M"
            label="사용기간"
            name="permitDates"
            v-model="searchParam.permitDates"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="회사 출입 중장비 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'entranceHeavyEquipment',
  data() {
    return {
      searchParam: {
        plantCd: null,
        permitDates: [],
      },
      grid: {
        merge: [
          {colName: 'sopWorkPermitId', index: 0},
          {colName: 'sopWorkPermitId', index: 1},
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            // 작업개요
            name: 'workSummary',
            field: 'workSummary',
            label: 'LBL0000211',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:250px',
          },
          {
            name: 'permitDate',
            field: 'permitDate',
            label: '사용일',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            sortable: true,
            style: 'width:130px',
          },
          {
            name: 'heavyEquipmentName',
            field: 'heavyEquipmentName',
            label: '중장비명', // 중장비명
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'amount',
            field: 'amount',
            label: '수량', // 수량
            align: 'right',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고', // 비고
            align: 'left',
            style: 'width:350px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.workPermit.entranceHeavyEquip.url
      // code setting
      // list setting
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = '작업허가서 상세';
      this.popupOptions.param = {
        sopWorkPermitId: row ? row.sopWorkPermitId : '',
        permitTypeCd: row ? row.permitTypeCd : 'SPT0000001',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
